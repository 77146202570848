/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

// BlogCategoryPage Route
import BlogCategoryPage from 'routes/templates/blog-category'

// Gatsby SEO Head
export function Head({ data }) {
  return (
    <>
      <title>{`${data.datoCmsCategory.name} | Blog | Pocketworks`}</title>
      <meta
        name="title"
        content={`${data.datoCmsCategory.name} | Blog | Pocketworks`}
      />
      <meta name="description" content={data.datoCmsCategory.description} />
    </>
  )
}

export default BlogCategoryPage

export const query = graphql`
  query BlogCategoryQuery($slug: String) {
    datoCmsCategory(slug: { eq: $slug }) {
      name
      description
      slug
    }
    allDatoCmsArticle(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      limit: 42
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          date(formatString: "MMMM D, YYYY")
          title
          slug
          excerpt
          featuredMedia {
            gatsbyImageData(
              placeholder: BLURRED
              imgixParams: {
                fit: "crop"
                ar: "3:2"
                fm: "jpg"
                maxW: 800
                auto: "compress"
                duotone: "f99f53,FA8072"
                duotoneAlpha: 6
              }
            )
          }
          author {
            name
            slug
            mainImage {
              gatsbyImageData(
                placeholder: TRACED_SVG
                imgixParams: {
                  fm: "jpg"
                  auto: "compress"
                  fit: "facearea"
                  facepad: 3.0
                  maxW: 200
                }
              )
              url
            }
          }
          categories {
            name
            slug
          }
        }
      }
    }
  }
`
